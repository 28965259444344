.logo {
  height: 30px;
  margin: 8px;
}
.logo_Landing{
  height: 50px;
}
.landing_main {
  padding: 2%;
  width: 100vw;
  height: 100vh;
}

.main_video {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeInRight 2s ease-in-out;
}

.main_video video {
  width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landing_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: lightgray;
  margin: auto;
}


.landing_header {
  font-weight: bolder;
  font-size: xx-large;
  color: antiquewhite;
  text-align: center;
}

.landing_header_text {
  font-size: large;
  z-index: 1;
  animation: fadeInLeft 1.5s ease-in-out;
  text-align: center;
}

.tryButton {
  font-size: 22px;
  font-weight: bold;
  text-decoration: none;
  color: white;
  background-color: #f75d34;
  border-color: #183d3d;
  padding: 10px 20px;
  border-radius: 10px;
  transition: box-shadow 0.3s, transform 0.3s;
}

.tryButton:hover {
  color: white;
  background-image: linear-gradient(to bottom right, #f75d34);
  border-color: #183d3d;
  box-shadow: 0px 5px 5px 0px black;
  transform: translateY(-5px);
}

.landing_first_container {
  margin: auto;
  width: 90% !important;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 50% 50%;
  box-shadow: 0 20px 40px #183d3d;
  border-radius: 10px;
  justify-content: space-between;
  animation: slideDown 0.8s ease-in-out;
  background: linear-gradient(#000, #183d3d 50vw);
  padding: 5%;
  gap: 10px;
}

.landing_footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1% 2%;
}

.landing_footer_text {
  color: lightgray;
  display: flex;
  align-items: center;
}

.share_buttons {
  display: flex;
  gap: 10px;
}

.icon_button {
  color: #fff;
  transition: box-shadow 0.3s, transform 0.3s;
  padding: 5px;
  font-size: 14px;
  border-radius: 0.25rem;
  height: 35px;
  min-width: 35px;
  outline: none !important;
}

.incompatible_modal_content {
  padding: 5%;
}

.landing_second_container {
  margin: 50px auto;
  color: antiquewhite;
  width: 90% !important;
  border-radius: 10px;
  background: linear-gradient(#183d3d, #000 40vw);
  animation: slideDown 0.8s ease-in-out;
  padding: 2%;
  text-align: center;
}

.second_container_content {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subcontent_header {
  font-size: x-large;
  font-weight: bold;
  color: #5c8374;
}

.subcontent_video {
  height: 200px;
}

.feature_div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: medium;
}

.landing_third_container {
  margin: auto;
  width: 90% !important;
  border-radius: 10px;
  background: linear-gradient(#000, #183d3d 20vw);
  padding: 2%;
  text-align: center;
  color: antiquewhite;
}

.third_container_div {
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: center;
  color: #5c8374;
}

.partners_list {
  display: flex;
  gap: 5%;
  justify-content: center;
}

img {
  margin: 10px;
  max-width: 70%;
  height: auto;
}

.partners_list_container {
  margin: 50px auto;
  color: antiquewhite;
  width: 90% !important;
  border-radius: 10px;
  background: linear-gradient(#000, #183d3d 7vw);
  animation: slideDown 0.8s ease-in-out;
  padding: 2%;
  text-align: center;
}

.diagram_list_header {
  color: antiquewhite;
}

.diagram_list {
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: center;
  font-weight: bold;
  color: lightgray;
}

.coming_soon {
  font-size: xx-small;
  border-radius: 10px;
  margin-left: 5px;
  padding: 3px 3px;
  border: 1px solid #97bc62;
  color: #97bc62;
  align-self: center;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .landing_first_container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 45% 55%;
    height: 75%;
    justify-content: space-between;
    padding: 5%;
    background: linear-gradient(#000, #183d3d);
  }

  .landing_second_container {
    background: linear-gradient(#183d3d, #000 50vw);
    box-shadow: 0 20px 40px #183d3d;
  }

  .landing_text {
    text-align: center;
    display: block;
  }

  .landing_header {
    font-size: x-large;
  }

  .landing_header_text {
    font-size: 0.9rem;
  }

  .main_video {
    margin-top: 10px;
    display: flex;
  }

  .main_video video {
    object-fit: contain;
    align-items: stretch;
    width: 100%;
    margin-top: 10px;
  }

  .tryButton {
    font-size: 16px;
  }

  .landing_footer {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .subcontent_video {
    height: 100px;
  }

  .second_container_content {
    flex-direction: column;
  }

  .subcontent_header {
    font-size: large;
  }

  .landing_subcontent {
    font-size: small;
    padding: 3%;
  }

  .partners_list {
    flex-wrap: wrap;
    gap: auto;
  }

  .diagram_list {
    display: block;
  }

  .partners_list_container {
    background: linear-gradient(#000, #183d3d 10vw);
  }
}
