img {
  object-fit: contain;
}

.accordion:not(.collapsed) {
  color: #ffffff;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}


.icon-button {
  color: #fff !important;
  background-image: linear-gradient(to bottom right, #000, #183d3d);
  background-color: #183d3d;
  border-color: transparent;
  transition: box-shadow 0.3s, transform 0.3s;
  font-size: 14px !important;
  border-radius: 0.25rem;
  height: 35px;
}

.icon-button:hover {
  background-image: linear-gradient(to bottom right, #183d3d, #000);
  color: yellowgreen !important;
}

.icon-buttons-container {
  display: flex;
  gap: 10px;
}

.delete-button{
  color: #fff !important;
  background-image: #E44057;
  background-color: #E44057 !important;
  border-color: transparent;
  transition: box-shadow 0.3s, transform 0.3s;
  font-size: 14px !important;
  border-radius: 0.25rem;
  height: 35px

}

// .delete-button:hover {
//   background-image: linear-gradient(to bottom right,  #E44057, #000);
//   // color: yellowgreen !important;
// }

.undo-container{
  display: flex;
  gap: 2px; 
}
.undo-button{
  color: #fff !important;
  background-image: linear-gradient(to bottom right, #000, #183d3d);
  background-color: #183d3d;
  border-color: transparent;
  transition: box-shadow 0.3s, transform 0.3s;
  font-size: 14px !important;
  border-radius: 0.25rem;
  height: 35px;
}


.ChatGPT{
  background-image: linear-gradient(to bottom right, #000, #183d3d); 
}

.cancel-button {
  margin-right: 50px;
  gap: 10px;
  background-color: #fff !important;
  color: #183d3d !important;
  border: 1px solid #183d3d !important;
  font-size: 14px !important;
}

.cancel-button:hover {
  color: yellowgreen !important;
}

.dlt-button {
  background-color: #183d3d;
  color: white;
  border-color: transparent;
}

.dlt-button:hover {
  background-color: #fff !important;
  color: #183d3d;
  border-color: #183d3d;
}

.Add-btn {
  background-color: #183d3d;
  border: #5c8374;
}
.Add-btn:hover {
  background-color: #5c8374 !important;
}
.Add-btn:active {
  transform: translateY(2px);
}

.text-white {
  background-color: #183d3d;
  border: #fff !important;
}
.text-white:hover {
  background-color: red !important;
}

.text-white:active {
  transform: translateY(2px);
}

.generate {
  background-color: #5c8374;
}

.menu-label {
  color: white;
  font-size: 14px;
}

.menu-label:hover {
  color: #5c8374;
}

.cform-select {
  color: black !important ;
  font-size: 14px;
}

.editor-menu {
  display: flex;
  justify-content: space-between;
  z-index: 10;
}

.editor-class {
  z-index: 10;
}

.buttonStyles {
  color: #fff !important;
  background-image: linear-gradient(to bottom right, #000, #183d3d);

  border-radius: 0.25rem;
  height: 35px;
}

.buttonStyles:hover {
  border-color: #183d3d;
  box-shadow: 0px 5px 5px 0px #183d3d;
  background-image: linear-gradient(to bottom right, #183d3d, #000);

  transform: translateY(-5px);
}
.run-button {
  background-image: linear-gradient(to bottom right, yellow, #183d3d);
  background-color: #5c8374;
  border-color: #183d3d;
  font-size: 14px;
  font-weight: bold;
}

.run-button:hover {
  background-image: linear-gradient(to bottom left, #183d3d, yellow);
  border-color: #183d3d;
}

.header {
  min-height: 3.2rem;
  padding: 0px;
  color: #fff;
  background-image: linear-gradient(to bottom left, #183d3d, #000);
  background-color: #183d3d;
  height: 6%;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  gap: 5px;
}

.sidebar {
  overflow-y: auto;
  background-color: #183d3d;
  background-image: linear-gradient(to bottom left, #000, #183d3d);
  padding: 5px;
  width: 14rem;
}

.sidebar:not(.sidebar-end) ~ * {
  --cui-sidebar-occupy-start: 14rem;
}

.accordion-button {
  font-size: 14px;
}

.form-control {
  font-size: 14px;
}

.dropdown-menu {
  font-size: 14px;
}

.header-nav {
  gap: 10px;
  align-items: center;
}

.footer {
  font-size: 12px;
}

.alignMiddle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.input-container {
  display: flex;
  align-items: center;
}

.input-container input,
.input-container button,
.input-container .img {
  margin-right: 20px;
}

.input-see{
  display: flex;
  align-items: center;
}

.expand-button {
  min-width: 15px !important;
  padding: 0 !important;
}

.image-style {
  max-height: 80vh;
  width: 100%;
  min-width: 400px;
}

.expand-button-editor {
  position: absolute !important;
  top: 46%;
  z-index: 999;
}

.expand-button-diagram {
  position: absolute !important;
  top: 50%;
  z-index: 10;
}

.content {
  width: 100%;
  max-width: 650px;
}

.MuiButtonBase-root {
  min-width: 0px !important;
  text-transform: none !important;
}

.dropdown-profile {
  background-color: #5c8374;
  color: #000;
}

.zoom-div {
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: flex;
  gap: 2px;
  flex-direction: column;
}

.zoom-button {
  background-color: transparent;
  border-radius: 50%;
  border: none;
}
.MuiMenu-paper {
  background-color: #183d3d !important;
  top: 90px;
  width: 180px;
  left: 225px;
}

.MuiBox-root {
  margin: 0% !important;
}


.MuiSelect-icon {
  color: gray !important;
}

.mode_menu {
  color: antiquewhite !important;
  font-size: 14px !important;
}

.MuiListSubheader-root {
  background-color: #183d3d !important;
}

.MuiMenuItem-root {
  color: antiquewhite !important;
  font-size: 14px !important;
  border: 1px solid transparent !important;
}

.mode_menu:hover {
  background-color: rgb(51, 88, 56) !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: rgb(235 255 0 / 8%) !important;
}


.MuiOutlinedInput-notchedOutline {
  border-color: #222a2a !important;
}

#mindMapId {
  width: 700px !important;
  height: 700px !important;
}

.col-3 {
  width: 50% !important;
}

.profile {
  display: flex;
  height: 110vh; 
  background-color: #F6F6F6;
  
}

.white-section {
  flex: 0 0 25%;
  background-color: white;

  margin: 10px;
  margin-left: 1%;
  border-radius: 1%;
  height: 100vh;
}

.about {
  flex: 0 0 72%;
  position: relative;
}
.about_me {
  color:#000000;
  font-size: 30px;
  width: 100%;
  height: 30px;
  padding: 2%;
  display: flex;
  justify-content: space-between;
}

.icons {
  display: flex;
  gap: 10px; /* Adjust as needed */
}

.userInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
  margin-left: 10%;
  color: #000000;
}

.gray-section {
  background-color: white;

  padding: 4%;
  margin-top: 3%;
  border-radius: 1%;
  margin-bottom: 1%;
}

.profile_picture {
  position: relative;
  width: 50%;
  height: 50%;
  align-items: center;
  margin: 20px;
  margin-left: 85px;
}

.form-floating {
  margin: 2px;
}

.userEmail {
  color: #000;
}

.col-md {
  background-color: #F6F6F6;
  color: #000000;
  margin: 10px;
  border-radius: 5px;
  padding: 5px;
}

.switch_data {
  margin: 10px;
}

.switch_icons {
  margin: 30px;
  margin-left: 0%;
}
.switch_icons:hover {
  color: blue;
}

.monaco-list-row {
  font-size: 12px; 
}

.menuItem:hover {
  background-color: lightgray;
}
.bg-lightt{
  position: relative;
  left: 10px;
  width: 60px;
  height: 50px;
  background-color: #fff;
 }
 
 #image0_238_351{
   align-items: center;
 }



 .tooltip-image {
  max-width: 150px;
  max-height: 120px; 
  width: 50%; 
  height: auto;
}

.tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.premium{
 position: relative;
 left: 20px;
}

.ai_topic {
  display: flex;
  flex-direction: column;
  align-items: center; 
  height: 100vh; 
  padding-top: 4%; 
  box-sizing: border-box; 
}

:root {
  --gradient-start: #ffffff;
  --gradient-end: #9db938;
}

.project {
  font-family: inherit;
  background: linear-gradient(50deg, var(--gradient-start), var(--gradient-end));
  
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 30px;
}

.logo_Landing {
  margin-bottom: 10px;
 }

 #filled-hidden-label-small{
  color: #000 !important;
  border-color: #000 !important;
}




.input-container {
  font-family: Inter;
  max-width: 94%;
  z-index: 1;
}



.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  padding: 16px;
 
  
 
}

.box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 512px;
  padding: 2px;
  border-radius: 4px;
  border: 0.82px solid rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0);
  opacity: 0.9;
  transition: background-color 0.3s, border-color 0.3s, opacity 0.3s;
  cursor: pointer;
}

.box img {
  width: 40px;
  height: 35px;
  margin-bottom: 8px;
}

.box p {
  margin: 0;
  font-size: 16px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: #d4d3d3;
}

.box:hover {
  background-color: #333;
  border-color: #fff;
  opacity: 1;
}

@media (max-width: 900px) {
  .box {
    flex: 1 1 calc(100% - 32px);
    max-width: calc(100% - 32px);
  }
  .container{
    transform: translateY(0px)
  }
}

@media (max-width: 480px) {
  .box {
    flex: 1 1 calc(100% - 16px);
    max-width: calc(100% - 16px);
    padding: 8px;
  }
  .container{
    transform: translateY(0px)
  }
}


.input-container {
  width: 100%;
  margin-top: 4%; 
  position: absolute;
  position: relative;
  margin-bottom: 2%;
}

.input-container .MuiOutlinedInput-root {
  border-radius: 8px;
  font-size: 16px;
  color: #fffefe !important;
}

/* Darker border */
.input-container .MuiOutlinedInput-root fieldset {
  border-color: #faf8f8 !important; /* Force dark border */
  transition: border-color 0.3s ease;
}

/* Slightly lighter on hover */
.input-container .MuiOutlinedInput-root:hover fieldset {
  border-color: #f1efef !important; /* Darker border on hover */
}

/* Lighter but still dark on focus */
.input-container .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #fffefe !important; /* Darker border on focus */
}

/* Input text styles */
.input-container .MuiInputBase-input {
  font-size: 20px;
  line-height: 1.5;
  color: #fcfcfc !important;
}

/* Adornment styles */
.input-container .MuiInputAdornment-root .MuiSvgIcon-root {
  color: #fcfbfb !important;
  transition: color 0.3s, transform 0.3s;
}

.input-container .MuiInputAdornment-root .MuiSvgIcon-root:hover {
  transform: scale(1.2);
  cursor: pointer;
}



@media (max-width: 768px) {
  .input-container {
    margin: 24px 0;
  }

  .error-message,
  .loading-box {
    top: auto;
    position: static;
    text-align: center;
  }
}

.error-message {
  color: red;
  margin-top: 8px;
}

.loading-box {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  color: white;
}

.select-list{
  background-color: #fff;
}

.disk{
  color: #d4d3d3;
  margin-top: 15px;
  margin-left: 17px;
}

.disk-submit{
  margin-left: 17px;
}

.file-name-display{
  margin-top: 10px;
  margin-left: 17px;
}
.saveheading{
  margin-bottom: 15px;
}







